import React, { useState } from "react";

const Filter = ({ onSearch }) => {
  const [initDate, setInitDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  function IconSearch(props) {
    return (
      <svg
        viewBox="0 0 788.5 1000"
        fill="currentColor"
        height="1em"
        width="1em"
        {...props}
      >
        <path d="M772 772c20 22.667 22 43.333 6 62l-46 46c-24 21.333-46.667 21.333-68 0L474 690c-49.333 28-101.333 42-156 42-85.333 0-159.667-31.667-223-95S0 499.333 0 414s30-158.333 90-219 132.667-91 218-91 160 31.667 224 95 96 137.667 96 223c0 58.667-15.333 112.667-46 162l190 188M94 414c0 58.667 22.667 110.667 68 156s97.333 68 156 68 109-21 151-63 63-93 63-153c0-58.667-22.667-110.333-68-155s-97.333-67-156-67-109 21-151 63-63 92.333-63 151" />
      </svg>
    );
  }
  const getMonday = (d) => {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday

    return new Date(d.setDate(diff));
  };

  const getDatesRange = (e) => {
    const option = e.target.value;
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const day = today.getDate();

    const last7DaysDate = new Date(year, month, day - 7);
    const last15DaysDate = new Date(year, month, day - 15);
    const last30DaysDate = new Date(year, month, day - 30);

    const startOfWeekDate = getMonday(today);

    const startOfLastWeekDate = getMonday(new Date(year, month, day - 7));
    const endOfLastWeekDate = new Date(year, month, day - 1);

    const startOfLastMonthDate = new Date(year, month - 1, 1);
    const endOfLastMonthDate = new Date(year, month, 0);

    const thisMonthDate = new Date(year, month, 1);
    const lastYearDate = new Date(year - 1, 0, 1);
    const thisYearDate = new Date(year, 0, 1);

    switch (option) {
      case "today":
        setInitDate(today);
        setEndDate(today);
        break;
      case "last-7-days":
        setInitDate(last7DaysDate);
        setEndDate(today);
        break;
      case "last-15-days":
        setInitDate(last15DaysDate);
        setEndDate(today);
        break;
      case "last-30-days":
        setInitDate(last30DaysDate);
        setEndDate(today);
        break;
      case "this-week":
        setInitDate(startOfWeekDate);
        setEndDate(today);
        break;
      case "last-week":
        setInitDate(startOfLastWeekDate);
        setEndDate(endOfLastWeekDate);
        break;
      case "this-month":
        setInitDate(thisMonthDate);
        setEndDate(today);
        break;
      case "last-month":
        setInitDate(startOfLastMonthDate);
        setEndDate(endOfLastMonthDate);
        break;
      case "this-year":
        setInitDate(thisYearDate);
        setEndDate(today);
        break;
      case "last-year":
        setInitDate(lastYearDate);
        setEndDate(new Date(year - 1, 11, 31));
        break;

      default:
        break;
    }
  };

  // const { init_date, end_date } = dates;

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const submitFilter = (e) => {
    e.preventDefault();
    const dates = {
      init_date: formatDate(initDate),
      end_date: formatDate(endDate),
    };

    onSearch && onSearch(dates);
  };

  return (
    <div className="filter card">
      <div className="card-body ">
        <form onSubmit={submitFilter} className="form-horizontal">
          <div className="row">
            <div className="col-3 col-s-12">
              <div className="form-group">
                <label>Fecha Inicial</label>
                <input
                  name="init_date"
                  type="date"
                  className="form-control"
                  onChange={(e) => setInitDate(new Date(e.target.value))}
                  value={initDate.toISOString().slice(0, 10)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Fecha final</label>
                <input
                  type="date"
                  name="end_date"
                  className="form-control"
                  onChange={(e) => setEndDate(new Date(e.target.value))}
                  value={endDate.toISOString().slice(0, 10)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>Date ranges</label>
                <select className="form-control" onChange={getDatesRange}>
                  <option value="today">Hoy</option>
                  <option value="last-7-days">Últimos 7 días</option>
                  <option value="last-15-days">Últimos 15 días</option>
                  <option value="last-30-days">Últimos 30 días</option>
                  <option value="this-week">Esta semana</option>
                  <option value="last-week">La semana pasada</option>
                  <option value="this-month">Este mes</option>
                  <option value="last-month">El mes pasado</option>
                  <option value="this-year">Este año</option>
                  <option value="last-year">El año pasado</option>
                </select>
              </div>
            </div>
            <div className="col-3">
              <div className="form-group">
                <label>&nbsp;</label>

                <button type="submit" className="btn btn-primary btn-block">
                  <IconSearch />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Filter;
