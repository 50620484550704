import React, { Fragment, useState }from 'react';
import Card from './Card';
import Filter from './Filter';
import Consolidated from './Consolidated';
import ConsolidatedUser from "./ConsolidatedUser";
import DataUser from "./DataUser";
import Table from "./Table";
import News from "./News";
import { PieChart } from "react-chartkick";
import "chart.js";

const Content = (props) => {
  const { app, render, setRender } = props;

  const [loading, setLoading] = useState(false);

  const elements = [
    { id: 1, name: "Cupones", full_path: "open_stats/coupons" },
    {
      id: 2,
      name: "Suscriptores Tiendas",
      full_path: "open_stats/subscribers",
    },
    { id: 3, name: "Tiendas Publicadas", full_path: "open_info/shops" },
    { id: 4, name: "Top 20", full_path: "open_stats/top_subscribers?top=true" },
    {
      id: 5,
      name: "Tiendas publicadas del",
      full_path: "open_stats/top_subscribers",
    },
  ];

  const [filterDates, setFilterDates] = useState({
    init_date: "",
    end_date: "",
  });

  const [dataCoupons, setDataCoupons] = useState({
    data: "",
    total: "",
  });

  const [dataSuscriptors, setDataSuscriptors] = useState({
    data: "",
    total: "",
  });
  const [dataShops, setDataShops] = useState({
    data: "",
    total: "",
  });
  const [dataTopShops, setDataTopShops] = useState({});
  const [totalTopShops, setTotalTopShops] = useState([]);

  const [dataConsolidated, setDataConsolidated] = useState([]);

  const [top, setTop] = useState([]);

  const [dataNews, setDataNews] = useState([]);

  const [dataUsers, setDataUsers] = useState({});

  const totals = (elm) => Object.values(elm).reduce((a, b) => a + b, 0);

  const search = (dates) => {
    const appName = app.name;

    setLoading(true);

    setFilterDates({
      ...filterDates,
      init_date: dates.init_date,
      end_date: dates.end_date,
    });

    // setRender();

    const isSingleApp =
      appName !== "Consolidado Cupones" &&
      appName !== "Tiendas Publicadas" &&
      appName !== "News Letter";

    if (isSingleApp) {
      searchSingleApp(dates);
    } else if (appName === "News Letter") {
      searchNewsLetter(dates);
    } else {
      searchConsolidated(dates, appName);
    }
  };

  const Loading = () => (
    <div className="card">
      <div className="card-body">
        <div className="card-title">
          <h3>Cargando...</h3>
        </div>
      </div>
    </div>
  );

  const searchSingleApp = (dates) => {
    elements.map((e) => {
      return fetch(`${app.path}${e.full_path}`, {
        method: "POST",
        body: JSON.stringify(dates),
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((resolve) => {
          const record = e.name;

          if (record === "Cupones") {
            setDataCoupons({
              ...dataCoupons,
              data: resolve,
              total: totals(resolve),
            });
          } else if (record === "Suscriptores Tiendas") {
            setDataSuscriptors({
              ...dataSuscriptors,
              data: resolve,
              total: totals(resolve),
            });
          } else if (record === "Tiendas Publicadas") {
            setDataShops({
              ...dataShops,
              data: resolve,
              total: totals(resolve),
            });
          } else if (record === "Tiendas publicadas del") {
            setDataTopShops({
              ...dataTopShops,
              resolve,
            });
          } else if (record === "Top 20") {
            let records = [];
            let totalTop = [];
            resolve.map((elm) => {
              records.push([elm["name"], elm["total"]]);
              totalTop.push(elm["total"]);
              return null;
            });
            setTop(records);
            setTotalTopShops([totalTop.reduce((a, b) => a + b, 0)]);
          }

          setRender();
          setLoading(false);
        });
    });
  };

  const fetchData = (data, site, url) => {
    return fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((resolve) => {
        return {
          site: site,
          data: resolve,
          total: totals(resolve),
        };
      });
  };

  const searchConsolidated = (dates, appName) => {
    const fullPath =
      appName === "Consolidado Cupones"
        ? "open_stats/coupons"
        : "open_info/shops";

    const promises = app.routes.map((site) => {
      const url = `${site["path"]}${fullPath}`;
      return fetchData(dates, site, url);
    });

    Promise.all(promises).then((resolve) => {
      setDataConsolidated(resolve);
      requestUsers(dates, fullPath);
      setLoading(false);
    });
  };

  const searchNewsLetter = (data) => {
    const fullPath = "open_info/news_letter_subscriber";
    let consolidated = [];
    app.routes.map((elm) => {
      return fetch(`${elm["path"]}${fullPath}`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => response.json())
        .then((resolve) => {
          // consolidated[elm.name] = {name: elm.name, data: resolve['app'], acum: resolve['total']}
          consolidated.push({
            name: elm.name,
            data: resolve["app"],
            acum: resolve["total"],
          });
          setDataNews({
            ...dataNews,
            consolidated,
          });
          setRender();
          setLoading(true);
        });
    });
  };

  const SingleApp = ({ dates }) => (
    <Fragment>
      <Card
        key={elements[0].id}
        name={elements[0].name}
        data={dataCoupons.data}
        total={dataCoupons.total}
      />
      <hr />
      <Card
        key={elements[1].id}
        name={elements[1].name}
        data={dataSuscriptors.data}
        total={dataSuscriptors.total}
      />
      <hr />
      <Card
        key={elements[2].id}
        name={elements[2].name}
        data={dataShops.data}
        total={dataShops.total}
      />
      <hr />
      <div className="card">
        <div className="card-body">
          <div className="card-title">
            <h3>{elements[3].name}</h3>
          </div>
          {top ? <PieChart data={top} /> : <Loading />}
          <p>
            Total: <span>{totalTopShops}</span>
          </p>
        </div>
      </div>
      <hr />

      <div className="card">
        <div className="card-body">
          <div className="card-title">
            <h3>{`${elements[4].name} ${dates.init_date} al ${dates.end_date}`}</h3>
          </div>

          <Table data={dataTopShops.resolve} />
        </div>
      </div>
    </Fragment>
  );

  const requestUsers = (dates, url) => {
    // let recordsUsers = [];
    const resolves = app.routes.map((elm) => {
      return fetch(
        `${elm["path"]}${
          url === "open_stats/coupons"
            ? "open_stats/coupons_user"
            : "open_info/shops_user"
        }`,
        {
          method: "POST",
          body: JSON.stringify(dates),
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((response) => response.json())
        .then((resolve) => {
          return {
            name: elm.name,
            data: resolve,
          };
        });
    });

    Promise.all(resolves).then((resolve) => {
      let recordsUsers = resolve.map((elm) => {
        return { name: elm.name, data: elm.data };
      });

      setDataUsers({
        ...dataUsers,
        recordsUsers,
      });

      setRender();
      setLoading(false);
    });
  };

  const isSingleApp =
    app.name !== "Consolidado Cupones" &&
    app.name !== "Tiendas Publicadas" &&
    app.name !== "News Letter";

  return (
    <div className="content">
      <div className="container-fluid">
        {app.name !== undefined ? (
          <>
            <h1 className="title">{app.name}</h1>
            <Filter onSearch={search} />
            <hr />
          </>
        ) : null}

        {loading ? <Loading /> : null}

        {!loading && render && isSingleApp ? (
          <SingleApp dates={filterDates} />
        ) : null}

        {!loading &&
        render &&
        (app.name === "Consolidado Cupones" ||
          app.name === "Tiendas Publicadas") ? (
          <>
            <Consolidated
              dataConsolidated={dataConsolidated}
              appName={app.name}
            />
            <hr />
            <ConsolidatedUser dataUsers={dataUsers} />
            <hr />
            <DataUser dataUsers={dataUsers} />
          </>
        ) : null}

        {!loading && render && app.name === "News Letter" ? (
          <News appName={app.name} data={dataNews} />
        ) : null}

        {!render && app.name === undefined ? (
          <div>
            <h1>Bienvenido a TurboMaster</h1>
            <p>
              Seleciona la app del panel izquierdo y elije la fecha para la cual
              deseas realizar la consulta
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Content;
