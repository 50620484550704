import React from 'react';
import { BrowserRouter, Switch} from 'react-router-dom';
import { LOGIN, PRIVATE, LOGOUT } from './config/router/path';
import PrivateRoute from './components/router/PrivateRoute';
import PublicRoute from './components/router/PublicRoute';
import Login from './components/Login';
import Logout from './components/Logout';
import Private from './components/Private';
import AuthContextProvider from './components/context/authContext';

const App = () => {

  return (
    <AuthContextProvider>
      <BrowserRouter>
        <Switch>
          <PrivateRoute path={PRIVATE} component={Private} />
          <PrivateRoute path={LOGOUT} component={Logout} />
          <PublicRoute path={LOGIN} component={Login} exact />
        </Switch>
      </BrowserRouter>
    </AuthContextProvider>
    
    
  );
}

export default App;
