import React, { useState } from 'react';
import useAuthContext from '../hooks/useAuthContext';
import LogoTurboInternet from '../images/logoTurboInternet.png'

const Login = () => {
  const authorized = { 
    email: [
      {mail: 'sebastian@turbointernet.co', passwordMail: 'Turbo4!'},
      {mail: 'cupones@turbointernet.co', passwordMail: 'Turbo5!'},
      {mail: 'gerencia@turbointernet.co', passwordMail: 'TurboInternet6!'},
      {mail: 'smoreno@turbointernet.co', passwordMail: 'TurboInternet7!'},
      {mail: 'sebasalex09@hotmail.com', passwordMail: 'TurboInterne8!'},
      {mail: 'alianzas@turbointernet.co', passwordMail: 'TurboInterne16!'}
    ]
  }
  const {loginSession} = useAuthContext();
  const [data, setData] = useState({
    email: '',
    password: ''
  })

  const getData = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    })
  }

  const submitLogin = e => {
    e.preventDefault();
    const mailAuthorized = authorized.email.find(elm => elm.mail === data.email)
    if (mailAuthorized && (mailAuthorized.mail === data.email && mailAuthorized.passwordMail === data.password)) {
      loginSession()
    }
  }

  const {email, password} = data

  return (
    <div className="login">
      <img src={LogoTurboInternet} alt="logo-Turbointernet" />
      {/* <h1 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '20px' }}>Analiticas Turbointernet</h1> */}
      <div className="card_login">
        <form onSubmit={submitLogin}>
          <div className="form-group">
            <h3>LOGIN</h3>
          </div>
          <div className="form-group">
            <input 
              type="email" 
              name="email" 
              placeholder="Email" 
              className="form-control"
              onChange={getData}
              value={email} />
          </div>
          <div className="form-group">
            <input 
              type="password" 
              name="password" 
              placeholder="Contraseña" 
              className="form-control"
              onChange={getData}
              value={password} />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-block btn-dark">INGRESAR</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login;