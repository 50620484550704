import React from 'react'
import { AreaChart } from 'react-chartkick';
import 'chart.js';

const CardNews = (props) => {
  const { data } = props

  return (
    <div className="card_box" key={data['name']}>
      <h4>{data['name']}</h4>
      <AreaChart data={data['data']} />
      <p><b>{`TOTAL ACUMULADO:  ${data['acum']}`}</b></p>
    </div>
  )
}

export default CardNews;