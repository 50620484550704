import React from 'react';
import { LineChart } from 'react-chartkick'
import 'chart.js';

const Card = (props) => {
  const { name, data, total } = props;

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <div className="card-title">
              <h3>{name}</h3>
            </div>
            {data ? <LineChart data={data} /> : <div>Cargando...</div>}
            <p>
              Total: <span>{total}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
