import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LOGOUT } from "../config/router/path";
import SideNav from "./SideNav";
import Content from "./Content";

const Private = () => {
  const [app, updateApp]  = useState({});
  const [render, updateRender]  = useState(false); 

  const setApp = (elem) => {
    updateApp(elem)
    updateRender(false)
  }

  const setRender = () => updateRender(true);

  return ( 
    <div className="app">
      <SideNav setApp={setApp}/>
      <Content 
        app={app}
        render={render}
        setRender={setRender}
      />
      <div className="logout btn btn-danger">
        <Link to={LOGOUT}>Cerrar Sesion</Link>
      </div>
    </div>
  );
}

export default Private;