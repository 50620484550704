import React, { useState } from "react";
import LogoTurbo from "../images/logoTurbo.jpeg";

const SideNav = ({ setApp }) => {
  const arrayApps = [
    {
      name: "turbocupones",
      path: "https://www.turbocupones.com/",
      displayName: "Turbo Cupones",
    },
    {
      name: "diariocritico",
      path: "https://www.descuentos.diariocritico.com/",
      displayName: "Diario Critico",
    },
    {
      name: "ahorraviajes",
      path: "https://www.ahorraviajes.com/",
      displayName: "Ahorraviajes",
    },
    {
      name: "cupones_hosting",
      path: "https://www.cupones.hosting/",
      displayName: "Cupones hosting",
    },
    {
      name: "rebajalo_es",
      path: "https://www.rebajalo.es/",
      displayName: "Rebajalo-es",
    },
    {
      name: "rebajalo_com_co",
      path: "https://www.rebajalo.com.co/",
      displayName: "Rebajalo-com-co",
    },
    {
      name: "turbocupones_mx",
      path: "https://www.turbocupones.com.mx/",
      displayName: "Turbocupones_mx",
    },
    {
      name: "cupones_online_cl",
      path: "https://www.cupones.online/cl/",
      displayName: "Cupones Online Chile",
    },
    {
      name: "cupones_online_pe",
      path: "https://www.cupones.online/pe/",
      displayName: "Cupones Online Perú",
    },
    {
      name: "espectador",
      path: "https://www.descuentos.elespectador.com/",
      displayName: "Espectador",
    },
    {
      name: "el_rancaguino",
      path: "https://descuentos.elrancaguino.cl/",
      displayName: "El Rancagüino",
    },
    {
      name: "cupponoff-ar",
      path: "https://www.cuponoff.com/ar/",
      displayName: "Cuponoff Argentina",
    },
    {
      name: "cupponoff-pe",
      path: "https://www.cuponoff.com/pe/",
      displayName: "Cuponoff Perú",
    },
    {
      name: "cupponoff-co",
      path: "https://www.cuponoff.com/co/",
      displayName: "Cuponoff Colombia",
    },
    {
      name: "cupponoff-ec",
      path: "https://www.cuponoff.com/ec/",
      displayName: "Cuponoff Ecuador",
    },
    {
      name: "cupponoff-cl",
      path: "https://www.cuponoff.com/cl/",
      displayName: "Cuponoff Chile",
    },
    // { name: 'pulzo', path: 'https://www.descuentos.pulzo.com/' },
  ];

  const [pages] = useState({
    turbocupones: {
      name: "Turbo Cupones",
      path: "https://www.turbocupones.com/",
    },
    diariocritico: {
      name: "Diario Critico",
      path: "https://www.descuentos.diariocritico.com/",
    },
    ahorraviajes: {
      name: "Ahorraviajes",
      path: "https://www.ahorraviajes.com/",
    },
    cupones_hosting: {
      name: "Cupones hosting",
      path: "https://www.cupones.hosting/",
    },
    rebajalo_es: { name: "Rebajalo-es", path: "https://www.rebajalo.es/" },
    rebajalo_com_co: {
      name: "Rebajalo-com-co",
      path: "https://www.rebajalo.com.co/",
    },
    turbocupones_mx: {
      name: "Turbocupones-mx",
      path: "https://www.turbocupones.com.mx/",
    },
    cupones_online_cl: {
      name: "Cupones Online Chile",
      path: "https://www.cupones.online/cl/",
    },
    cupones_online_pe: {
      name: "Cupones Online Perú",
      path: "https://www.cupones.online/pe/",
    },
    espectador: {
      name: "Espectador",
      path: "https://www.descuentos.elespectador.com/",
    },
    // pulzo: { name: 'pulzo', path: 'https://www.descuentos.pulzo.com/' },
    el_rancaguino: {
      name: "El Rancagüino",
      path: "https://descuentos.elrancaguino.cl/",
    },
    cuponoff_ar: {
      name: "Cuponoff Argentina",
      path: "https://www.cuponoff.com/ar/",
    },
    cuponoff_pe: {
      name: "Cuponoff Perú",
      path: "https://www.cuponoff.com/pe/",
    },
    cuponoff_co: {
      name: "Cuponoff Colombia",
      path: "https://www.cuponoff.com/co/",
    },
    cuponoff_ec: {
      name: "Cuponoff Ecuador",
      path: "https://www.cuponoff.com/ec/",
    },
    cuponoff_cl: {
      name: "Cuponoff Chile",
      path: "https://www.cuponoff.com/cl/",
    },

    consolidado: { name: "Consolidado Cupones", routes: arrayApps },
    tiendas_publicadas: { name: "Tiendas Publicadas", routes: arrayApps },
    suscriptores_tienda: { name: "suscriptores_tienda", routes: arrayApps },
    news_letter: { name: "News Letter", routes: arrayApps },
  });

  const getApp = (e) => {
    let nameApp = e.target.getAttribute("name");
    setApp(pages[nameApp]);
  };

  return (
    <div className="sidenav">
      <div className="logo-turbo">
        <img src={LogoTurbo} alt="logo-turbo" width="100px" />
      </div>
      <ul>
        <li onClick={getApp} name="consolidado" className="btn">
          Consolidado Cupones
        </li>
        <li onClick={getApp} name="tiendas_publicadas" className="btn">
          Tiendas Publicadas
        </li>
        <li onClick={getApp} name="news_letter" className="btn">
          Newsletters
        </li>
        <li className="empty-menu"></li>
        {/* <li onClick={getApp} name="suscriptores_tienda" className="btn">Suscriptores Totales</li> */}
        <li onClick={getApp} name="turbocupones" className="btn">
          Turbo Cupones
        </li>
        <li onClick={getApp} name="diariocritico" className="btn">
          Diario Critico
        </li>
        <li onClick={getApp} name="ahorraviajes" className="btn">
          Ahorraviajes
        </li>
        <li onClick={getApp} name="cupones_hosting" className="btn">
          Cupones hosting
        </li>
        <li onClick={getApp} name="rebajalo_es" className="btn">
          Rebajalo-es
        </li>
        <li onClick={getApp} name="rebajalo_com_co" className="btn">
          Rebajalo-com-co
        </li>
        <li onClick={getApp} name="turbocupones_mx" className="btn">
          Turbocupones_mx
        </li>
        <li className="empty-menu"></li>

        <li onClick={getApp} name="cupones_online_cl" className="btn">
          Cupones Online Chile
        </li>
        <li onClick={getApp} name="cupones_online_pe" className="btn">
          Cupones Online Perú
        </li>
        <li className="empty-menu"></li>

        <li onClick={getApp} name="espectador" className="btn">
          Espectador
        </li>

        <li onClick={getApp} name="el_rancaguino" className="btn">
          El Rancagüino
        </li>
        <li className="empty-menu"></li>

        {/* <li onClick={getApp} name="pulzo" className="btn">Pulzo</li> */}
        <li onClick={getApp} name="cuponoff_ar" className="btn">
          Cuponoff Argentina
        </li>
        <li onClick={getApp} name="cuponoff_pe" className="btn">
          Cuponoff Perú
        </li>
        <li onClick={getApp} name="cuponoff_co" className="btn">
          Cuponoff Colombia
        </li>
        <li onClick={getApp} name="cuponoff_ec" className="btn">
          Cuponoff Ecuador
        </li>
        <li onClick={getApp} name="cuponoff_cl" className="btn">
          Cuponoff Chile
        </li>

        {/* <li onClick={getApp} name="cuponoff_ar" className="btn">cuponoff-ar</li>
        <li onClick={getApp} name="cuponoff_cl" className="btn">cuponoff-cl</li>
        <li onClick={getApp} name="cuponoff_co" className="btn">cuponoff-co</li>
        <li onClick={getApp} name="cuponoff_ec" className="btn">cuponoff-ec</li>
        <li onClick={getApp} name="cuponoff_pe" className="btn">cuponoff-pe</li>     */}
      </ul>
    </div>
  );
};

export default SideNav;
