import React from "react";
import { PieChart } from "react-chartkick";
import "chart.js";

const ConsolidatedUser = ({ dataUsers }) => {
  const allData = [];

  dataUsers.recordsUsers.forEach((elm) => {
    elm.data.forEach((u) => {
      const user = allData.find((d) => d.name === u[0]);
      if (user == null) {
        allData.push({ name: u[0], total: u[1] });
      } else {
        user.total += u[1];
      }
    });
  });

  const pieData = allData.map((elm) => {
    return [elm.name, elm.total];
  });

  return (
    <div className="card">
      <div className="card-body">
        <div className="card-title">
          <h3>Consolidado Por Usuario</h3>
        </div>

        {pieData ? <PieChart data={pieData} donut={true} /> : null}

        <p>
          <b>{`TOTAL:  ${pieData.reduce((acc, elm) => {
            return acc + elm[1];
          }, 0)}`}</b>
        </p>
      </div>
    </div>
  );
};

export default ConsolidatedUser;
