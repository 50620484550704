import React, { useState, useEffect } from 'react';
import { PieChart } from 'react-chartkick'
import "chart.js";

const Consolidated = ({ dataConsolidated, appName }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const orderedData = dataConsolidated.sort((a, b) => b.total - a.total);

    const pieData = orderedData.map((elm) => {
      return [elm.site.name, elm.total];
    });

    setData(pieData);
  }, [dataConsolidated]);

  return (
    <div className="card">
      <div className="card-body">
        <div className="card-title">
          <h3>{appName}</h3>
        </div>
        {data ? <PieChart data={data} /> : <div>Cargando...</div>}
        <div className="info">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>App</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {dataConsolidated
                ? dataConsolidated.map((siteData) => {
                    return (
                      <tr key={siteData.site.name}>
                        <td>
                          <a
                            href={siteData.site.path}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {siteData.site.displayName}
                          </a>
                        </td>
                        <td>{siteData.total}</td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
            <tfoot>
              <tr>
                <td>Total</td>
                <td>
                  {dataConsolidated
                    ? dataConsolidated.reduce((acc, elm) => {
                        return acc + elm.total;
                      }, 0)
                    : null}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Consolidated;
