import React from "react";
import { PieChart } from "react-chartkick";
import "chart.js";

const DataUser = ({ dataUsers }) => {
  const data = dataUsers;

  const getTotal = (elm) => {
    let total = 0;
    elm.map((e) => (total += e[1]));
    return total;
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="card-title">
          <h3>Consolidado Por Web</h3>
        </div>
        <div className="row">
          {data.recordsUsers
            ? data.recordsUsers.map((elm) => {
                return (
                  <div
                    className="col-sm-12 col-md-2 col-lg-4"
                    key={elm["name"]}
                    style={{ width: "100%", marginBottom: "1rem" }}
                  >
                    <div className="card">
                      <div className="card-header">{elm["name"]}</div>
                      <div className="card-body">
                        {elm["data"].length !== 0 ? (
                          <PieChart data={elm["data"]} />
                        ) : (
                          "No hay Información"
                        )}
                        <p className="text-center mt-3">
                          <b>{`TOTAL:  ${getTotal(elm["data"])}`}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default DataUser;
