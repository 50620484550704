import React, { useState, useEffect } from 'react';

const Table = (props) => {
  const { data } = props;

  return (
    <div className="card_box">
      <div className="info">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>No.</th>
              <th>Tienda</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {data
              ? data.map((elm, i) => {
                  return (
                    <tr key={elm["name"]}>
                      <td>{i + 1}</td>
                      <td>{elm["name"]}</td>
                      <td>{elm["total"]}</td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
