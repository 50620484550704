import React, { Fragment } from 'react'
import CardNews from './CardNews';

const News = (props) => {
  const { data } = props

  return (
    <Fragment>
      {data.consolidated ? data.consolidated.map(elm => {
        return(
          <CardNews 
            key={elm['name']}
            data={elm}  />
        )
      })
    : null}
    </Fragment>
  )
}

export default News;