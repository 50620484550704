import React, { createContext, useCallback, useMemo, useState, useEffect } from "react";

const MY_AUTH_APP = 'MY_AUTH_APP';
const LAST_VISIT_TIME = 'LAST_VISIT_TIME';

export const AuthContext = createContext();

export default function AuthContextProvider({children}) {
  const [isAuthenticated, setIsAuthenticated] = useState(window.localStorage.getItem(MY_AUTH_APP));

  const loginSession = useCallback(() => {
    window.localStorage.setItem('MY_AUTH_APP', true);
    setIsAuthenticated(true);
  }, []);

  const logoutSession = useCallback(() => {
    window.localStorage.removeItem('MY_AUTH_APP', true);
    setIsAuthenticated(false);
  }, []);

  const clearLocalStorage = useCallback(() => {
    window.localStorage.removeItem('MY_AUTH_APP', true);
    setIsAuthenticated(false);
  }, []);

  // useEffect(() => {
  //   const timer = setTimeout(clearLocalStorage, 60000); // 1 hora en milisegundos

  //   return () => clearTimeout(timer); // Limpiar el temporizador al desmontar el componente
  // }, [clearLocalStorage]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      const currentTime = new Date().getTime();
      window.localStorage.setItem(LAST_VISIT_TIME, currentTime.toString());
    };

    const handlePageLoad = () => {
      const lastVisitTime = window.localStorage.getItem(LAST_VISIT_TIME);
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - parseInt(lastVisitTime);

      if (elapsedTime >= 60000) {
        clearLocalStorage();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('load', handlePageLoad);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('load', handlePageLoad);
    };
  }, [clearLocalStorage]);

  const value = useMemo(() => ({
    loginSession,
    logoutSession,
    isAuthenticated
  }), [isAuthenticated, loginSession, logoutSession]);

  return (
    <AuthContext.Provider value={value} >
      {children}
    </AuthContext.Provider>
  )
}